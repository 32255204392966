import React, { useState, useMemo, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useGlobalHook } from '@devhammed/use-global-hook'
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import MyAxios, {check_response} from "./MyAxios";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { Table } from "./Table";
import { MostSubmitButton, WarningIcon, Check } from "./components/MostComponents";
import { Riservato, } from "./components/OpusComponents";
import InVisionDialog from "./components/InVisionDialog";
import SettingsIcon from '@material-ui/icons/Settings';
import SearchIcon from '@material-ui/icons/Search';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Collapse from '@material-ui/core/Collapse';
import PropagateLoader from "react-spinners/PropagateLoader";
import { css } from "@emotion/core";

/**
 * Component for showing dossier rows
 *
 * @component
 */
export const Dossier = () => {
  const history = useHistory();
  const { handleSubmit } = useForm();
  const { t } = useTranslation(["dossier"]);
  const [loading, setLoading] = useState(true);
  const [dossierPersonali, setDossierPersonali] = useState([]); //elenco dossier
  const [dossierPubblici, setDossierPubblici] = useState([]); //elenco dossier
  const [dossierVisione, setDossierVisione] = useState([]); //elenco dossier
  const [checkedPubblici, setCheckedPubblici] = React.useState(false);
  const { setAlert1, setContent } = useGlobalHook('alertStore');
  const appAlert = useCallback((text) => {
    setContent(text);
    setAlert1(true);
  }, [setContent,setAlert1])
  const spinnerCss = css`
    display: block;
    margin: 0 auto;
  `;
  useEffect(() => {
    MyAxios.get("dossieropera").then((response) => {
      //console.log("dossieropera",response);
      let data = check_response(response);
      if(!data.success) {
        console.error(data.error)
        appAlert(data.error)
        return
      }
      for (let dossier_type of ["ret_owner", "ret_public", "ret_vision"]) {
          data[dossier_type].forEach((r) => {
            for (let e of [ "fruibilitaopera_id", "statusopera_id" ]) {
              if(r[e])
                r[e] = t("dossier:" + e + "." + r[e]);
              else
                r[e] = null
            }
            // fruibilitadossier_id arriva come numero. ma arriva anche  la struttura fruibilitadossierdetail
            r["fruibilitadossier_id"] = t("dossier:fruibilitadossier_id." + r['fruibilitadossierdetail']['code']);
            r["tiposupporto_id"] = t("dossier:tiposupporto_id." + r['tiposupporto_id']);
            if (r["tipoopera_id"]) {
                r["tipoopera_id"] = r['tipooperadetail']['description'];
            }
          });
      };
      setDossierPersonali(data.ret_owner);
      setDossierPubblici(data.ret_public);
      setDossierVisione(data.ret_vision);
      setLoading(false)
    })
    .catch(function (error) {
        console.error(error);
        appAlert(error.message?error.message:JSON.stringify(error));
    });
  }, [t,appAlert]);

  const handleChangePubblici = () => {
    setCheckedPubblici((prev) => !prev);
  };

  const columns = useMemo(() => {
    const proprietarioCol = {
        Header: t("dossier:Proprietario"),
        columns: [
          {
            Header: "",
            accessor: "username",
            Cell: ({ cell: { value }, row: { original } }) => {
             const ui = original.useridentitydetail
             if(ui)
                return (
                  <span>
                    {ui.nome + " " + ui.cognome + " (" + value + ")"}
                    <Riservato reserved={original.riservatezzaproprietario} />
                  </span>
                )
             if(!value)
                return (
                    <Riservato reserved={true} />
                )
             return (
                  <span>
                    {value}
                    <Riservato reserved={original.riservatezzaproprietario} />
                  </span>
             )
            }
          },
        ]}
    const autoreCol = {
        Header: t("dossier:Autore"),
        columns: [
          {
            Header: t("dossier:Cognome"),
            accessor: "autoredetail.cognome",
          },
          {
            Header: t("dossier:Nome"),
            accessor: "autoredetail.nome",
          },
          {
            Header: t("dossier:Detto"),
            accessor: "autoredetail.nomeinarte",
          }
        ]
    }

    let columns = []
    // 0 : propri dossier
    // 1 : dossier di altri
    for (let i=0; i<2; i++) {
      let icon
      if (i)
        icon = <SearchIcon />
      else
        icon = <SettingsIcon />
      const operaColColumns = [
          {
            Header: t("dossier:Dettaglio"),
            accessor: "id",
            Cell: ({ cell: { value }, row: { original } }) => {
             return (
                <Tooltip title={t("Apri dettaglio")}>
                  <Link
                    to={{
                      pathname: "/dossierdetail",
                      state: { dossier_id: value },
                    }}
                    className="nodecoration allCellLink"
                  >
                    {icon}
                  </Link>
                </Tooltip>
            )},
          },
          {
            Header: t("dossier:NomeOpera"),
            accessor: "nomeopera",
            Cell: ({ cell: { value }, row: { original } }) => {
             return (
                <Tooltip title={t("Apri dettaglio")}>
                  <Link
                    to={{
                      pathname: "/dossierdetail",
                      state: { dossier_id: original.id },
                    }}
                    className="nodecoration allCellLink"
                  >
                    {value}
                  </Link>
                </Tooltip>
            )},
          },
          {
            Header: t("dossier:TipoOpera"),
            accessor: "tipoopera_id",
          },
          /*
          {
            Header: t("dossier:TipoSupporto"),
            accessor: "tiposupporto_id",
          },
          */
          {
            Header: t("dossier:LuogoOpera"),
            accessor: "luogooperadetail.citta",
            Cell: ({ cell: { value }, row: { original } }) => {
             let tipoluogo_id = ""
             if (original.luogooperadetail && original.luogooperadetail.tipoluogo_id)
                tipoluogo_id = t("dossier:tipoluogo_id." + original.luogooperadetail.tipoluogo_id)
             return (
                  <span>
                    {original.luogooperadetail ? original.luogooperadetail.citta + " " + original.luogooperadetail.indirizzo + " " + original.luogooperadetail.nazione + " (" + tipoluogo_id + ")" : ""}
                    <Riservato reserved={original.riservatezzaluogo} />
                  </span>
             )}
          },
          {
            Header: t("dossier:StatoOpera"),
            accessor: "statusopera_id",
            Cell: ({ cell: { value }, row: { original } }) => {
             return (
                  <span>
                    {value}
                    <Riservato reserved={original.riservatezzastatus} />
                  </span>
             )}
          },
          {
            Header: t("dossier:FruibilitaOpera"),
            accessor: "fruibilitaopera_id",
          },
        ]
      const operaCol = {
        Header: t("Opera"),
        columns: operaColColumns
      }

      const altroColColumns = [
          {
            Header: t("dossier:In BC"),
            accessor: "contract_initialized",
            Cell: ({ cell: { value }, row: { original } }) => {
                if(value) {
                    if(original.docs_bcsync)
                        return (
                            <Check good={true} />
                        )
                    return (
                        <WarningIcon />
                    )
                }
                return (
                    <Check good={false} />
                )
            },
          },
        ]
      if(i===0) {
          altroColColumns.push({
            Header: t("dossier:InVisione"),
            accessor: "visible_cnt",
            Cell: ({ cell: { value }, row: { original } }) => {
              if(value) {
                const inVisionRows = original.inVisionRows
                return (<InVisionDialog inVisionRows={inVisionRows}/>)
              } else {
                return ""
              }
            }
          })
          altroColColumns.push({
            Header: t("dossier:FruibilitaDossier"),
            accessor: "fruibilitadossier_id",
          })
      }
      const altroCol = {
        Header: t("dossier:Dossier"),
        columns: altroColColumns
      }

      /*
      if(i)
        columns.push([proprietarioCol, autoreCol, operaCol, altroCol])
      else
        columns.push([autoreCol, operaCol, altroCol])
      */
      columns.push([proprietarioCol, autoreCol, operaCol, altroCol])
    }
    return columns;
  }, [t]);
  //console.log(columns[0])

  const onSubmit = () => {
    history.push('/newdossier');
  }

  // anche ospiti possono avere token
  return (
    <div>
      <Header />
      <h1>{t("dossier:DossierHeader")}</h1>

        <h2>{t("dossier:DossierPersonali")}</h2>
        <div className="blackColor margin20 gray">
        { loading ?
            null
        :
            <React.Fragment>
            { dossierPersonali.length ? (
                <Table columns={columns[0]} data={dossierPersonali} />
            ) : "Nessun dossier presente" }
            <div>
                <form onSubmit={handleSubmit(onSubmit)} >
                    <div className="MuiContainer-root MuiContainer-maxWidthXs">
                    <MostSubmitButton label={t("dossier:NuovoDossier")} />
                    </div>
                </form>
            </div>
            </React.Fragment>
        }
        </div>

      { loading ?
        <PropagateLoader color="#AAAA00" css={spinnerCss} loading={loading} />
      :
        <React.Fragment>

          <h2>{t("dossier:DossierVisione")}</h2>
          <div className="blackColor">
          { dossierVisione.length ? (
            <React.Fragment>
              <div className="margin20 gray">
                  <Table columns={columns[1]} data={dossierVisione} />
              </div>
            </React.Fragment>
          ) : "Nessun dossier presente" }
          </div>

          <h2>{t("dossier:DossierPubblici")}</h2>
          <div className="blackColor">
          { dossierPubblici.length ? (
            <React.Fragment>
              <FormControlLabel
                control={<Switch color="primary" checked={checkedPubblici} onChange={handleChangePubblici} />}
                label={t("Mostra")+" ("+dossierPubblici.length+" "+t("dossier")+")"}
              />
              <div className="margin20 gray">
                <Collapse in={checkedPubblici}>
                  <Table columns={columns[1]} data={dossierPubblici} />
                </Collapse>
              </div>
            </React.Fragment>
          ) : "Nessun dossier presente" }
          </div>

        </React.Fragment>
      }

      <Footer />
    </div>
  );
};
